
<style>
.c-box {
	background-color: #222736 !important;
}
</style>

<script>
function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

export default {
	props: ['isHomepage'],
	components: {

	},
	data() {
		return {
			personaError: false,
			personaData: null
		};
	},
	async created() {
		window.addEventListener("scroll", this.windowScroll);
		this.personaData = false;
		this.personaError = true;
		/*
		const avatar = getCookie('avatar');
		const display_name = getCookie('display_name');
		if(avatar && display_name) {
			setTimeout(() => {
				this.personaData = {
					avatar: avatar,
					display_name: display_name
				};
			}, 100);
		} else {
			this.personaData = false;
			this.personaError = true;
		}
		 */
	},

	async requestPersona() {
		try {
			let url = new URL(`https://api.cftools.cloud/app/v1/@me/persona`);

			let response = await fetch(url, {
				method: 'GET',
				credentials: 'include'
			});

			if(response.ok) {
				let data = await response.json();
				if(data.status) this.personaData = data.persona;
				else this.personaError = true;
			} else {
				throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			}

		} catch (error) {
			this.personaError = true;
			console.log(`[ERROR] ${error}`);
		}
	},
	destroyed() {
		window.removeEventListener("scroll", this.windowScroll);
	},
	mounted() {
		if(process.env.NODE_ENV === 'production') {
			let currentUri = window.location.href;
			if(currentUri.startsWith('https://cftools.cloud')) {
				const upstreamUri = currentUri.replace('https://cftools.cloud', 'https://cftools.com');
				window.location.href = upstreamUri;
			}
		}
	},
	methods: {
		onMenuClick(event) {
			event.preventDefault();
			const nextEl = event.target.nextSibling;
			if (nextEl && !nextEl.classList.contains("show")) {
				const parentEl = event.target.parentNode;
				if (parentEl) {
					parentEl.classList.remove("show");
				}
				nextEl.classList.add("show");
			} else if (nextEl) {
				nextEl.classList.remove("show");
			}
			return false;
		},
		/**
		 * Window scroll method
		 */
		windowScroll() {
			const navbar = document.getElementById("navbar");
			if (
					document.body.scrollTop >= 50 ||
					document.documentElement.scrollTop >= 50
			) {
				navbar.classList.add("nav-sticky");
			} else {
				navbar.classList.remove("nav-sticky");
			}
		},
		/**
		 * Toggle menu
		 */
		toggleMenu() {
			document.getElementById("topnav-menu-content").classList.toggle("show");
		},
		nextSlide() {
			this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
		},
		prevSlide() {
			this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
		}
	}
};
</script>

<template>
	<nav class="navbar navbar-expand-lg navigation fixed-top sticky c-box" id="navbar">
		<div class="container">
			<a class="navbar-logo" href="/">
				<img src="https://cdn.cftools.de/brand/cloud/white-250x.png" alt height="35" class="logo logo-light"/>
				<img src="https://cdn.cftools.de/brand/cloud/white-250x.png" alt height="35" class="logo logo-dark"/>
			</a>

			<button
					type="button"
					class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
					data-toggle="collapse"
					data-target="#topnav-menu-content"
					@click="toggleMenu()"
			>
				<i class="fa fa-fw fa-bars"></i>
			</button>

			<div class="collapse navbar-collapse" id="topnav-menu-content">
				<ul
						class="navbar-nav ml-auto"
						id="topnav-menu"
						v-scroll-spy-active="{ selector: 'a.nav-link' }"
				>
					<template v-if="isHomepage">
						<li class="nav-item">
							<a class="nav-link" v-scroll-to="'#home'" href="javascript: void(0);">Home</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" v-scroll-to="'#about'" href="javascript: void(0);">About</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" v-scroll-to="'#features'" href="javascript: void(0);">Features</a>
						</li>

						<li class="nav-item">
							<a class="nav-link" v-scroll-to="'#games'" href="javascript: void(0);">
								Supported games
							</a>
						</li>
						<!--
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle arrow-none" id="topnav-components" role="button" @click="onMenuClick" href="javascript: void(0);">
								More Solutions
								<div class="arrow-down"></div>
							</a>
							<div class="dropdown-menu" aria-labelledby="topnav-dashboard">
								<a href="/omega-manager" class="col dropdown-item side-nav-link-ref">
									OmegaManager
								</a>
							</div>
						</li>
						-->
						<li class="nav-item">
							<a class="nav-link" target="_blank" href="https://help.cftools.com/">
								Help & Support
							</a>
						</li>
					</template>
					<template v-else>
						<li class="nav-item">
							<router-link class="nav-link" to="/">Home</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/">About</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/">Features</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/">Supported games</router-link>
						</li>
						<!--
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle arrow-none" id="topnav-components" role="button" @click="onMenuClick" href="javascript: void(0);">
								More Solutions
								<div class="arrow-down"></div>
							</a>
							<div class="dropdown-menu" aria-labelledby="topnav-dashboard">
								<a href="/omega-manager" class="col dropdown-item side-nav-link-ref">
									OmegaManager
								</a>
							</div>
						</li>
						-->
						<li class="nav-item">
							<a class="nav-link" target="_blank" href="https://help.cftools.com/">
								Help & Support
							</a>
						</li>
					</template>
					<!--
					<li class="nav-item">
						<a class="nav-link" v-scroll-to="'#roadmap'" href="javascript: void(0);">Roadmap</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" v-scroll-to="'#news'" href="javascript: void(0);">News</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" v-scroll-to="'#faqs'" href="javascript: void(0);">FAQs</a>
					</li>
					-->
				</ul>
				<div class="ml-lg-2">
					<a href="https://discord.com/invite/k7Zdw6cXSH" class="btn btn-outline-light w-xs"
					   style="border-color: white; border-radius: 0;">
						<i class="fab fa-discord align-self-center mr-1"></i> Join our Discord
					</a>
				</div>
				<div class="ml-lg-2 hidden-sm">
					<a href="https://app.cftools.cloud" class="btn btn-outline-success w-xs" style="border-radius: 0;">
						Sign in
					</a>
				</div>
			</div>
		</div>
	</nav>
</template>